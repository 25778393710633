<template>
  <div class="popup__bg">
    <div class="popup w-1040">
      <div class="popup__container">
        <div class="popup__interactive"><div class="ny-static"></div></div>
        <div class="popup__article" data-lenis-prevent>
          <div class="popup__article-content popup__ny">
            <div
              class="popup__title"
              v-html="activeContent.title"
              :class="{
                'popup__title--small': activeContent.small_title,
                'popup__title--mini': activeContent.mini_title,
                'popup__title--small-mobile': activeContent.small_title_mobile,
                'popup__title--mini-mobile': activeContent.mini_title_mobile,
              }"
            ></div>
            <div class="popup__subtitle" v-html="activeContent.info"></div>
            <div class="popup__text popup__text--ny" v-html="activeContent.text"></div>
          </div>
        </div>
        <div class="popup__close" @click="$emit('close')"><span></span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Popup_3",
  props: {
    activeContent: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="sass">
@import '@/assets/sass/functions.sass'
@import '@/assets/sass/popup.sass'
.ny-static
	background-image: url(../assets/img/ny-poster.jpg)
	width: 100%
	height: 100%
	background-size: contain
	@media screen and (max-width: 1000px)
		height: 93vw
		background-repeat: no-repeat
		background-size: cover
.popup__text--ny
	ul
		color: #FFB49A
		margin-bottom: vw(20px)
		@media screen and (max-width: 1000px)
			margin-bottom: vwm(20px)
	li
		padding-left: vw(20px)
		margin-bottom: vw(10px)
		position: relative
		@media screen and (max-width: 1000px)
			padding-left: vwm(13px)
			margin-bottom: vwm(10px)
		&:before
			content: ''
			position: absolute
			left: 0
			top: 0.4vw
			width: vw(4px)
			min-width: vw(4px)
			height: vw(4px)
			border-radius: 50%
			background-color: #FFB49A
			@media screen and (max-width: 1000px)
				width: vwm(4px)
				height: vwm(4px)
				top: 0.8vw
.higlighted-subtitle
	color: #FFB49A
.popup__ny
	.popup__subtitle
		font-weight: 500
	@media screen and (max-width: 1000px)
		.popup__title
			text-align: center
			font-size: 10vw
			line-height: 1.1
		.popup__subtitle
			text-align: center
</style>
