<template>
  <div class="fgame-wrapper">
    <div class="fgame">
      <div id="scene">
        <div class="fgame__bg">
          <img src="../assets/img/find-game/game.webp" alt="" />
        </div>
        <div class="fgame-elements">
          <div :class="`fgame-el fgame-el--${i}`" v-for="i in total" :key="i">
            <img
              :src="require(`../assets/img/find-game/elements/${i}.png`)"
              @click="open(i)"
              :class="{ 'opened-image': founded.includes(i) }"
              alt=""
            />
          </div>
        </div>
      </div>
      <div v-if="showIntro" class="fgame__intro">
        <img src="../assets/img/find-game/cursor.png" alt="" />
        <div class="fgame__text">
          Зажмите курсор и перемещайте<br />
          иллюстрацию, чтобы найти героев
        </div>
        <div class="fgame__button">
          <a href="javascript:void(0)" @click="start">понятно</a>
        </div>
      </div>
    </div>
    <div class="fgame-panel" v-if="started">
      <div class="fgame-panel__text">
        Героев найдено: {{ founded.length }}/{{ total }}
      </div>
      <div class="fgame-panel__elements dragscroll">
        <img
          :src="
            require(`../assets/img/find-game/elements/${
              !founded.includes(i) ? 'shadow-' : ''
            }${i}.png`)
          "
          v-for="i in total"
          :key="i"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import dragscroll from "dragscroll";
import panzoom from "panzoom";
export default {
  name: "Fgame",
  data() {
    return {
      showIntro: true,
      founded: [],
      total: 11,
      started: false,
    };
  },
  methods: {
    start() {
      this.started = true;
      this.showIntro = false;
      setTimeout(() => {
        dragscroll.reset();
        var element = document.querySelector("#scene");
        panzoom(element, {
          maxZoom: 0.5,
          minZoom: 0.5,
          initialZoom: 0.5,
          bounds: true,
          boundsPadding: 1,
          transformOrigin: {x: 0.5, y: 0.5}
        });
      }, 100);
    },
    open(i) {
      if (!this.founded.includes(i)) {
        this.founded.push(i);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/sass/functions.sass'
.fgame
    width: 100%
    height: 100%
    position: relative
    scrollbar-width: none
    &-wrapper
        width: 100%
        height: 100%
        position: relative
    &::webkit-scrollbar
        display: none
    &__intro
        position: absolute
        width: 100%
        height: 100%
        background-color: rgba(6, 0, 31, 0.7)
        z-index: 100
        top: 0
        padding-top: vw(215px)
        padding-left: vw(270px)
        img
            width: vw(78px)
            height: vw(54px)
    &-elements
        position: absolute
        top: 0
        width: vw(4000px)
        height: vw(1845px)
        user-select: none
        transition: all 300ms
    &-el
        position: absolute
        img
            width: 100%
            transition: all 300ms
            height: auto
        &--1
            width: vw(623px)
            height: vw(422px)
            left: vw(584px)
            top: vw(384px)
        &--2
            width: vw(514px)
            height: vw(667px)
            top: vw(1085px)
            right: vw(919px)
        &--3
            width: vw(304px)
            height: vw(300px)
            top: vw(395px)
            right: vw(1338px)
        &--4
            width: vw(319px)
            height: vw(214px)
            top: vw(587px)
            left: vw(1154px)
        &--5
            width: vw(325px)
            height: vw(408px)
            top: vw(701px)
            right: vw(434px)
        &--6
            width: vw(249px)
            height: vw(423px)
            top: vw(646px)
            right: vw(774px)
        &--7
            width: vw(270px)
            height: vw(338px)
            top: vw(1070px)
            right: vw(190px)
        &--8
            width: vw(498px)
            height: vw(428px)
            top: vw(950px)
            left: vw(1398px)
        &--9
            width: vw(254px)
            height: vw(125px)
            top: vw(217px)
            right: vw(469px)
        &--10
            width: vw(257px)
            height: vw(323px)
            top: vw(355px)
            left: vw(254px)
        &--11
            width: vw(315px)
            height: vw(296px)
            top: vw(627px)
            right: vw(1234px)
    &-panel
        position: absolute
        z-index: 100
        bottom: vw(10px)
        display: flex
        flex-direction: column
        &__text
            background-color: #fff
            bottom: vw(100px)
            left: 0
            right: 0
            margin: auto
            width: auto
            padding: vw(5px) vw(10px)
            font-size: vw(15px)
            font-weight: 500
            color: var(--Orange, rgba(255, 79, 18, 1))
            border-radius: vw(10px)
            margin-bottom: vw(10px)
        &__elements
            border-radius: vw(20px)
            background: rgba(255, 255, 255, 1)
            width: vw(600px)
            height: vw(92px)
            margin-left: vw(10px)
            padding-top: vw(10px)
            padding-bottom: vw(10px)
            display: flex
            overflow: hidden
            img
                height: vw(64px)
                margin-right: 8px
    &__bg
        width: vw(4000px)
        height: vw(1845px)
        transition: all 300ms
        img
            width: 100%
            height: 100%
    &__text
        position: absolute
        z-index: 100
        top: vw(282px)
        left: vw(195px)
        color: var(--White, rgba(255, 255, 255, 1))
        font-size: vw(15px)
        line-height: vw(22px)
        font-weight: 500
    &__button
        position: absolute
        top: vw(332px)
        left: vw(230px)
        z-index: 100
        display: flex
        a
            width: vw(160px)
            height: vw(50px)
            background-color: #FF4F12
            text-align: center
            font-family: 'Tektur'
            font-weight: 500
            line-height: vw(51px)
            color: #fff
            font-size: vw(16px)
            letter-spacing: vw(1px)
            border-radius: vw(2px)
            backdrop-filter: blur(12.5px)
            filter: drop-shadow(0px 6.10394px 20.9278px rgba(0, 0, 0, 0.65))
            text-transform: uppercase
            @media screen and (max-width: 1000px)
              line-height: vwm(45px)
              font-size: vwm(16px)
.opened-image
    -webkit-filter: drop-shadow(6px 6px 0 white) drop-shadow(-6px 6px 0 white) drop-shadow(6px -6px 0 white) drop-shadow(-6px -6px 0 white)
    filter: drop-shadow(6px 6px 0 white) drop-shadow(-6px 6px 0 white) drop-shadow(6px -6px 0 white) drop-shadow(-6px -6px 0 white)
#scene
    width: vw(4000px)
    height: vw(1845px)
</style>
