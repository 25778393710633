<template>
  <div class="find-game">
    <div class="find-game__welcome" v-if="!isStarted">
      <div class="find-game__welcome-el-1"></div>
      <div class="find-game__welcome-el-2"></div>
      <div class="find-game__welcome-el-3"></div>
      <div class="find-game__welcome-content">
        <div class="find-game__welcome-bg">
          <svg
            width="302"
            height="179"
            viewBox="0 0 302 179"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.5 178.5L0 11.5L301.028 0L273.854 161.757L25.5 178.5Z"
              fill="#F3ECDA"
            />
          </svg>
        </div>
        <div class="find-game__welcome-title">Госуслуги<br />рядом</div>
        <div class="find-game__welcome-button">
          <a href="javascript:void(0)" @click="isStarted = true"><span>Играть</span></a>
        </div>
      </div>
    </div>
    <FindGameBox v-else />
  </div>
</template>

<script>
import FindGameBox from "./FindGameBox";
export default {
  name: "FindGame",
  data() {
    return {
      isStarted: false,
    };
  },
  components: {
    FindGameBox,
  },
};
</script>

<style lang="sass">
@import '@/assets/sass/functions.sass'
.find-game
    width: 100%
    height: 100%
    &__welcome
        width: 100%
        height: 100%
        background-image: url('../assets/img/find-game/welcome-bg.jpg')
        display: block
        background-size: cover
        background-position: center
        @media screen and (max-width: 1000px)
          height: vwm(475px)
        &-content
            position: absolute
            top: vw(179px)
            width: vw(301px)
            left: vw(128px)
        &-bg
            position: absolute
            width: vw(301px)
            height: vw(179px)
            svg
                width: 100%
                height: 100%
        &-title
            position: absolute
            z-index: 10
            text-align: center
            font-size: vw(47px)
            font-weight: bold
            transform: rotate(-4deg)
            line-height: vw(42px)
            color: rgba(68, 25, 114, 1)
            top: vw(40px)
            left: vw(35px)
        &-button
            position: absolute
            z-index: 10
            top: vw(130px)
            left: vw(45px)
            a
                display: block
                width: vw(223px)
                height: vw(97px)
                text-align: center
                background-image: url(../assets/img/find-game/button.png)
                background-size: contain
                background-repeat: no-repeat
                span
                    text-transform: uppercase
                    transform: rotate(-8deg)
                    display: block
                    color: rgba(255, 255, 255, 1)
                    font-size: vw(39px)
                    font-weight: bold
                    padding-top: vw(32px)
                    transition: all 500ms
                    &:hover
                        color: rgba(255, 79, 18, 1)
                    &:active
                        text-shadow: 0 0 10px rgba(255, 255, 255, 0.5)
        &-el-1
            pointer-events: none
            width: vw(620px)
            height: vw(480px)
            background-image: url(../assets/img/find-game/el-1.png)
            background-size: cover
            background-repeat: no-repeat
            position: absolute
            left: vw(15px)
            bottom: 0
            z-index: 4
        &-el-2
            pointer-events: none
            width: 100%
            height: 100%
            background-image: url(../assets/img/find-game/el-2.png)
            background-size: cover
            background-repeat: no-repeat
            position: absolute
            left: 0
            bottom: 0
            z-index: 2
        &-el-3
            pointer-events: none
            width: 100%
            height: 100%
            background-image: url(../assets/img/find-game/el-3.png)
            background-size: cover
            background-repeat: no-repeat
            position: absolute
            left: vw(-5px)
            bottom: 0
            z-index: 1
</style>
