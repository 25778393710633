var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup__bg"},[_c('div',{staticClass:"popup w-1040"},[_c('div',{staticClass:"popup__container"},[_vm._m(0),_c('div',{staticClass:"popup__article",attrs:{"data-lenis-prevent":""}},[_c('div',{staticClass:"popup__article-content popup__ny"},[_c('div',{staticClass:"popup__title",class:{
              'popup__title--small': _vm.activeContent.small_title,
              'popup__title--mini': _vm.activeContent.mini_title,
              'popup__title--small-mobile': _vm.activeContent.small_title_mobile,
              'popup__title--mini-mobile': _vm.activeContent.mini_title_mobile,
            },domProps:{"innerHTML":_vm._s(_vm.activeContent.title)}}),_c('div',{staticClass:"popup__subtitle",domProps:{"innerHTML":_vm._s(_vm.activeContent.info)}}),_c('div',{staticClass:"popup__text popup__text--ny",domProps:{"innerHTML":_vm._s(_vm.activeContent.text)}})])]),_c('div',{staticClass:"popup__close",on:{"click":function($event){return _vm.$emit('close')}}},[_c('span')])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup__interactive"},[_c('div',{staticClass:"ny-static"})])
}]

export { render, staticRenderFns }