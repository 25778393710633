<template>
  <div class="connections">
    <div class="connections__welcome" v-if="!isStarted">
      <!-- <div class="connections__el">
        <div class="connections__sparkles connections__sparkles--top"></div>
        <div class="connections__sparkles connections__sparkles--bottom"></div>
      </div> -->
      <div class="connections__toys">
        <div class="connections__toy connections__toy-1"></div>
        <div class="connections__toy connections__toy-2"></div>
        <div class="connections__toy connections__toy-3"></div>
        <div class="connections__toy connections__toy-4"></div>
        <div class="connections__toy connections__toy-5"></div>
        <div class="connections__toy connections__toy-6"></div>
      </div>
      <div class="connections__welcome-title">Связь поколений</div>
      <div class="connections__button">
        <a href="javascript:void(0)" @click="initSlider">начинаем</a>
      </div>
    </div>
    <div class="connections__slider" v-else>
      <div class="connections__el">
        <div class="connections__sparkles connections__sparkles--top"></div>
        <div class="connections__sparkles connections__sparkles--bottom"></div>
      </div>
      <div class="swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="slide in slides" :key="slide.id">
            <img :src="`/connections/${slide.id}.jpg`" alt="" />
          </div>
        </div>
      </div>
      <div class="connections__nav">
        <div class="connections__nav-arrow" @click="slidePrev"></div>
        <div class="connections__nav-text connections__nav-text--mobile">
          {{ active }}/{{ slides.length }}
        </div>
        <div class="connections__nav-text connections__nav-text--desktop">
          {{ slides.find((s) => s.id == active).name }}
        </div>
        <div
          class="connections__nav-arrow connections__nav-arrow--last"
          @click="slideNext"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
export default {
  name: "Connections",
  data() {
    return {
      slider: null,
      isStarted: false,
      index: 1,
    };
  },
  props: {
    slides: {
      type: Array,
      required: true,
    },
    active: {
      type: Number,
      required: true,
    },
  },
  methods: {
    initSlider() {
      this.isStarted = true;
      let that = this;
      setTimeout(() => {
        this.$emit("change");
        this.slider = new Swiper(".swiper", {
          direction: "horizontal",
          slidesPerView: 1,
          loop: false,
          on: {
            slideChange: function () {
              let i = this.realIndex + 1;
              that.index = i;
              that.$emit("set", i);
            },
          },
        });
      }, 100);
    },
    slideNext() {
      this.slider.slideNext();
      this.$emit("set", this.index);
    },
    slidePrev() {
      this.slider.slidePrev();
      this.$emit("set", this.index);
    },
  },
};
</script>

<style lang="sass">
@import '@/assets/sass/functions.sass'
.connections
  display: block
  width: 100%
  height: 100%
  position: relative
  @media screen and (max-width: 1000px)
    height: vwm(336px)
  &__nav
    width: 100%
    height: vw(50px)
    display: flex
    align-items: center
    justify-content: space-between
    position: absolute
    font-family: 'Tektur'
    font-size: vw(19px)
    color: #fff
    position: absolute
    bottom: vw(58px)
    background-color: rgba(255, 79, 18, 0.6)
    left: 0
    right: 0
    margin: auto
    z-index: 30
    @media screen and (max-width: 1000px)
      width: vwm(171px)
      height: vwm(40px)
      font-size: vwm(24px)
      background: none
      bottom: vwm(20px)
      color: rgba(255, 79, 18, 1)
    &-text
      &--mobile
        display: none
        @media screen and (max-width: 1000px)
          display: flex
          width: vwm(110px)
          text-align: center
          align-items: center
          justify-content: center
      &--desktop
        display: block
        @media screen and (max-width: 1000px)
          display: none
    &-arrow
      width: vw(50px)
      height: vw(50px)
      background-image: url(../assets/img/arrow-slider.svg)
      background-color: rgba(255, 79, 18, 1)
      background-size: vw(50px)
      cursor: pointer
      background-repeat: no-repeat
      background-position: 50% 50%
      @media screen and (max-width: 1000px)
        width: vwm(50px)
        background-size: vwm(40px)
        height: vwm(40px)
      &--last
        transform: rotate(180deg)
  &__slider
    display: flex
    height: 100%
    align-items: center
    z-index: 1
    img
      width: 100%
      height: auto
  &__button
    display: flex
    align-items: center
    justify-content: center
    margin-top: vw(-7px)
    @media screen and (max-width: 1000px)
      margin-top: vw(-10px)
    a
      width: vw(220px)
      height: vw(51px)
      background-color: #FF4F12
      text-align: center
      font-family: 'Tektur'
      font-weight: 500
      line-height: vw(51px)
      color: #fff
      font-size: vw(16px)
      letter-spacing: vw(1px)
      border-radius: vw(2px)
      backdrop-filter: blur(12.5px)
      filter: drop-shadow(0px 6.10394px 20.9278px rgba(0, 0, 0, 0.65))
      text-transform: uppercase
      @media screen and (max-width: 1000px)
        width: vwm(206px)
        height: vwm(45px)
        line-height: vwm(45px)
        font-size: vwm(16px)
  &__welcome
    width: 100%
    height: 100%
    background-size: contain
    background-repeat: no-repeat
    background-position: 50% 50%
    background-image: url(../assets/img/connections-welcome.jpg)
    display: flex
    flex-direction: column
    &-title
      font-size:  vw(84px)
      color: #fff
      opacity: 0.9
      margin-top: vw(358px)
      font-family: 'Great Vibes'
      margin-left: auto
      margin-right: auto
      @media screen and (max-width: 1000px)
        font-size: vwm(47px)
        margin-top: vwm(174px)
        margin-bottom: vwm(10px)
    &-subtitle
      font-size:  vw(106px)
      color: #fff
      opacity: 0.9
      font-family: 'Great Vibes'
      margin-top: vw(-78px)
      margin-left: vw(233px)
      @media screen and (max-width: 1000px)
        font-size: vwm(58px)
        margin-left: vwm(122px)
        margin-top: vwm(-43px)

  &:after
    content: ''
    display: block
    touch-action: none
    position: absolute
    pointer-events: none
    left: 0
    top: 0
    right: 0
    background-image: url('../assets/img/connections_bg.png')
    width: 100%
    height: 100%
    background-size: contain
    background-repeat: no-repeat
    background-position: 50% 50%
    z-index: 10
    @media screen and (max-width: 1000px)
      background-image: url('../assets/img/connections_bg-mob.png')
      background-size: 104%

  &__toy 
    position: absolute
    background-size: contain
    background-repeat: no-repeat
    &-1 
      background-image: url(../assets/img/toy-1.png)
      width: vw(91px)
      height: vw(213px)
      top: vw(49px)
      left: vw(7px)
      z-index: 10
      transform-origin: top center
      animation: swing1 4s linear infinite
      @media screen and (max-width: 1000px)
        width: vwm(51px)
        height: vwm(173px)
        top: vwm(25px)
        left: vwm(3px)
    &-2 
      background-image: url(../assets/img/toy-2.png)
      width: vw(91px)
      height: vw(106px)
      top: vw(56px)
      left: vw(39px)
      transform-origin: top center
      animation: swing2 4s linear infinite
      @media screen and (max-width: 1000px) 
        width: vwm(57px)
        height: vwm(72px)
        top: vwm(49px)
        left: vwm(30px)
    &-3 
      background-image: url(../assets/img/toy-3.png)
      width: vw(49px)
      height: vw(82px)
      top: vw(47px)
      left: vw(141px)
      transform-origin: top center
      animation: swing3 4s linear infinite
      @media screen and (max-width: 1000px)
        width: vwm(27px)
        height: vwm(60px)
        top: vwm(44px)
        left: vwm(95px)
    &-4 
      background-image: url(../assets/img/toy-4.png)
      width: vw(77px)
      height: vw(133px)
      top: vw(49px)
      right: vw(105px)
      z-index: 10
      transform-origin: top center
      animation: swing4 4s linear infinite
      @media screen and (max-width: 1000px)
        width: vwm(46px)
        height: vwm(102px)
        top: vwm(32px)
        right: vwm(54px)
    &-5 
      background-image: url(../assets/img/toy-5.png)
      width: vw(83px)
      height: vw(120px)
      top: vw(41px)
      right: vw(37px)
      transform-origin: top center
      animation: swing5 4s linear infinite
      @media screen and (max-width: 1000px)
        width: vwm(43px)
        height: vwm(80px)
        top: vwm(40px)
        right: vw(60px)
    &-6 
      background-image: url(../assets/img/toy-6.png)
      width: vw(77px)
      height: vw(203px)
      top: vw(49px)
      right: vw(10px)
      transform-origin: top center
      animation: swing6 3s linear infinite
      @media screen and (max-width: 1000px)
        width: vwm(41px)
        height: vwm(167px)
        top: vwm(49px)
        right: vwm(3px)

@keyframes swing1
  0%
    transform: rotate(-5deg)
  50%
    transform: rotate(5deg)
  100%
    transform: rotate(-5deg)
@keyframes swing2
  0%
    transform: rotate(3deg)
  50%
    transform: rotate(-3deg)
  100%
    transform: rotate(3deg)
@keyframes swing3
  0%
    transform: rotate(-5deg)
  50%
    transform: rotate(5deg)
  100%
    transform: rotate(-5deg)
@keyframes swing4
  0%
    transform: rotate(5deg)
  50%
    transform: rotate(-5deg)
  100%
    transform: rotate(5deg)
@keyframes swing5
  0%
    transform: rotate(-5deg)
  50%
    transform: rotate(5deg)
  100%
    transform: rotate(-5deg)
@keyframes swing6
  0%
    transform: rotate(-4deg)
  50%
    transform: rotate(4deg)
  100%
    transform: rotate(-4deg)
</style>

<style lang="scss" scoped>
@import "@/assets/sass/functions.sass";
.connections__el {
  height: 100%;
  position: absolute;
  width: 100%;
}

/* Общий стиль для блесток */
.connections__sparkles {
  position: absolute;
  width: vw(110px);
  height: vw(70px);
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0;
}

/* Верхние блестки */
.connections__sparkles--top {
  top: 0;
  left: 0;
  z-index: 100;
  animation: sparkle-animation-top1 4s linear infinite;
  background-image: url("../assets/img/bright.svg");
  opacity: 1 !important;
}

/* Нижние блестки */
.connections__sparkles--bottom {
  bottom: 0;
  right: 0;
  z-index: 100;
  animation: sparkle-animation-bottom1 4s linear infinite;
  opacity: 1 !important;
}

@keyframes sparkle-animation-top1 {
  0% {
    transform: rotate(180deg);
    left: 0;
    background-image: url("../assets/img/bright.svg");
  }
  10% {
    transform: rotate(180deg);
    left: 10%;
    background-image: url("../assets/img/bright-2.svg");
  }
  20% {
    transform: rotate(180deg);
    left: 20%;
    background-image: url("../assets/img/bright-3.svg");
  }
  30% {
    transform: rotate(180deg);
    left: 30%;
    background-image: url("../assets/img/bright-2.svg");
  }
  40% {
    transform: rotate(180deg);
    left: 40%;
    background-image: url("../assets/img/bright.svg");
  }
  50% {
    transform: rotate(180deg);
    left: 50%;
    background-image: url("../assets/img/bright-3.svg");
  }
  60% {
    transform: rotate(180deg);
    left: 60%;
    background-image: url("../assets/img/bright-2.svg");
  }
  70% {
    transform: rotate(180deg);
    left: 70%;
    background-image: url("../assets/img/bright.svg");
  }
  80% {
    transform: rotate(180deg);
    left: 80%;
    background-image: url("../assets/img/bright-3.svg");
  }
  90% {
    transform: rotate(180deg);
    left: 90%;
    background-image: url("../assets/img/bright-2.svg");
  }
  100% {
    transform: rotate(180deg);
    left: 100%;
    background-image: url("../assets/img/bright.svg");
  }
}

@keyframes sparkle-animation-top {
  0% {
    transform: translate(0%, -50%) rotate(180deg) scale(0) rotate(0deg);
    opacity: 0;
  }
  5% {
    transform: translate(100%, 0) rotate(180deg) scale(1.2) rotate(10deg);
    opacity: 1;
  }
  20% {
    transform: translate(300%, -10px) rotate(180deg) scale(1) rotate(0deg);
    opacity: 1;
  }
  25% {
    transform: translate(800%, -50%) rotate(180deg) scale(0) rotate(0deg);
    opacity: 0;
  }
  60% {
    transform: translate(800%, -50%) rotate(180deg) scale(0) rotate(0deg);
    opacity: 0;
  }
  65% {
    transform: translate(500%, 0) rotate(180deg) scale(1.2) rotate(10deg);
    opacity: 1;
  }
  70% {
    transform: translate(300%, -10px) rotate(180deg) scale(1) rotate(0deg);
    opacity: 1;
  }
  80% {
    transform: translate(100%, -50%) rotate(180deg) scale(1) rotate(0deg);
    opacity: 0;
  }
  100% {
    transform: translate(-100%, -50%) rotate(180deg);
    opacity: 0;
  }
}

/* Анимация для нижних блесток */
@keyframes sparkle-animation-bottom {
  0% {
    transform: translate(0%, 50%) scale(0) rotate(0deg);
    opacity: 0;
  }
  5% {
    transform: translate(100%, 0) scale(1.2) rotate(10deg);
    opacity: 1;
  }
  20% {
    transform: translate(300%, 10px) scale(1) rotate(0deg);
    opacity: 1;
  }
  25% {
    transform: translate(800%, 50%) scale(0) rotate(0deg);
    opacity: 0;
  }
  60% {
    transform: translate(800%, 50%) scale(0) rotate(0deg);
    opacity: 0;
  }
  65% {
    transform: translate(500%, 0) scale(1.2) rotate(10deg);
    opacity: 1;
  }
  70% {
    transform: translate(300%, 10px) scale(1) rotate(0deg);
    opacity: 1;
  }
  80% {
    transform: translate(100%, 50%) scale(1) rotate(0deg);
    opacity: 0;
  }
  100% {
    transform: translate(-100%, 50%);
    opacity: 0;
  }
}

@keyframes sparkle-animation-bottom1 {
  0% {
    right: 0;
    background-image: url("../assets/img/bright.svg");
  }
  10% {
    right: 10%;
    background-image: url("../assets/img/bright-2.svg");
  }
  20% {
    right: 20%;
    background-image: url("../assets/img/bright-3.svg");
  }
  30% {
    right: 30%;
    background-image: url("../assets/img/bright-2.svg");
  }
  40% {
    right: 40%;
    background-image: url("../assets/img/bright.svg");
  }
  50% {
    right: 50%;
    background-image: url("../assets/img/bright-3.svg");
  }
  60% {
    right: 60%;
    background-image: url("../assets/img/bright-2.svg");
  }
  70% {
    right: 70%;
    background-image: url("../assets/img/bright.svg");
  }
  80% {
    right: 80%;
    background-image: url("../assets/img/bright-3.svg");
  }
  90% {
    right: 90%;
    background-image: url("../assets/img/bright-2.svg");
  }
  100% {
    right: 100%;
    background-image: url("../assets/img/bright.svg");
  }
}
</style>
