<template>
  <div class="popup__bg">
    <div class="popup w-1040">
      <div class="popup__container">
        <div class="popup__interactive"><FindGame /></div>
        <div class="popup__article" data-lenis-prevent>
          <div class="popup__article-content">
            <div
              class="popup__title"
              v-html="activeContent.title"
              :class="{
                'popup__title--small': activeContent.small_title,
                'popup__title--mini': activeContent.mini_title,
                'popup__title--small-mobile': activeContent.small_title_mobile,
                'popup__title--mini-mobile': activeContent.mini_title_mobile,
              }"
            ></div>
            <div class="popup__subtitle" v-html="activeContent.info"></div>
            <div class="popup__text" v-html="activeContent.text"></div>
          </div>
        </div>
        <div class="popup__close" @click="$emit('close')"><span></span></div>
      </div>
    </div>
  </div>
</template>

<script>
import FindGame from '@/components/FindGame'
export default {
  name: "Popup_3",
  props: {
    activeContent: {
      type: Object,
      required: true,
    },
  },
  components: {
	FindGame,
  },
};
</script>

<style lang="sass">
@import '@/assets/sass/functions.sass'
@import '@/assets/sass/popup.sass'
</style>
