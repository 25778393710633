<template>
  <div class="interface">
    <div class="interface__bg" v-if="isVisibleHelp"></div>
    <Popup v-if="progressNumber > 99.9"></Popup>
    <Burger v-if="burger" @close="close" :handlerText="handlerText" />
    <Info v-if="info" @closeRef="closeRef" />
    <template v-if="!isMobile">
      <audio ref="audio1" src="@/assets/sounds/aac/1.aac" loop></audio>
      <audio ref="audio2" src="@/assets/sounds/aac/2.aac" loop></audio>
      <audio ref="audio3" src="@/assets/sounds/aac/3.aac" loop></audio>
      <audio ref="audio4" src="@/assets/sounds/aac/4.aac" loop></audio>
      <audio ref="audio5" src="@/assets/sounds/aac/5.aac" loop></audio>
      <audio ref="audio6" src="@/assets/sounds/aac/6.aac" loop></audio>
      <audio ref="audio7" src="@/assets/sounds/aac/7.aac" loop></audio>
      <audio ref="audio8" src="@/assets/sounds/aac/8.aac" loop></audio>
    </template>
    <template v-else>
      <audio ref="audio1" src="@/assets/sounds/aac/6.aac" loop></audio>
      <audio ref="audio6" src="@/assets/sounds/aac/6.aac" loop></audio>
      <audio ref="audio7" src="@/assets/sounds/aac/7.aac" loop></audio>
      <audio ref="audio8" src="@/assets/sounds/aac/8.aac" loop></audio>
    </template>
    <audio
      ref="audioElement"
      src="@/assets/sounds/aac/transition.aac"
      preload="auto"
      id="transform"
      loop
    ></audio>
    <div class="interface__box">
      <transition name="fade">
        <div class="interface__info" v-if="isVisibleHelp">
          <div class="interface__info-image mobile">
            <img src="@/assets/img/phone.svg" alt="" />
          </div>
          <div class="interface__info-control">
            <div class="interface__info-image mouse desktop">
              <img src="@/assets/img/mouse.svg" alt="" />
            </div>
            <div class="interface__info-image touchpad desktop">
              <img src="@/assets/img/touchpad.svg" alt="" />
            </div>
          </div>
          <p class="desk">
            Чтобы путешествовать во времени, <br />крутите колёсико мышки <br />или
            свайпайте по тачпаду
          </p>
          <p class="mob">
            Чтобы путешествовать <br />во времени, коснитесь экрана <br />и проведите верх
            или вниз
          </p>
          <a
            href="javascript:void(0)"
            class="welcome__button welcome__button--2 welcome__button--3 mobile-only"
            @click="openInterface"
            >Понятно</a
          >
        </div>
      </transition>
      <div class="interface__intro">
        <!-- <div class="interface__day-night"></div> -->
        <div class="interface__ref" @click="openRef"></div>
        <div class="interface__menu" @click="open"></div>
        <div
          class="interface__sound"
          @click="toggleMusic"
          :class="{ 'interface__sound--disabled': !isMusicEnabled }"
        ></div>
        <div class="interface__back" @click="$emit('openWelcome')">
          <div class="interface__back-icon"></div>
          <div class="interface__back-title">чудеса <br />на связи!</div>
        </div>
        <div class="interface__line" v-if="isMobile">
          <!-- <div class="move"></div> -->
          <span :style="`width: ${progress}`"
            ><div class="js-handler invisible-handler">
              <span class="hanlder js-handler" :class="{ mini: isMiniHandler }">{{
                handlerText
              }}</span>
              <div class="space"></div></div
          ></span>
        </div>
        <div class="interface__line" v-else>
          <!-- <div class="move"></div> -->
          <span :style="`width: ${progress}`"
            ><div class="js-handler invisible-handler">
              <span class="hanlder js-handler" :class="{ mini: isMiniHandler }">{{
                handlerText
              }}</span>
              <div class="space"></div></div
          ></span>
        </div>
        <button
          class="js-toggle-scroll play-button"
          v-if="!welcome && !autoScroll"
          @click="playScroll"
        ></button>
        <button
          class="js-toggle-scroll play-button play-button--pause"
          v-if="!welcome && autoScroll"
          @click="$emit('togglecsroll')"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import Burger from "@/components/Burger";
import Info from "@/components/Info";
import Popup from "@/components/Popup_11";
export default {
  name: "Interface",
  components: {
    Burger,
    Popup,
    Info,
  },
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    sound: {
      type: Boolean,
      required: true,
    },
    activeContent: {
      type: Object,
      required: false,
    },
    welcome: {
      type: Boolean,
      required: false,
    },
    autoScroll: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      burger: false,
      info: false,
      startScreenOpen: false,
      progress: 0,
      isDragging: false,
      startClientX: 0,
      startScrollTop: 0,
      handlerText: "VI век до н.э.",
      isMiniHandler: true,
      isMusicEnabled: true,
      progressNumber: 0,
      isVisibleHelp: false,
      isMobile: false,
    };
  },
  methods: {
    playScroll() {
      this.$emit("togglecsroll");
      this.sendEvent("play");
    },
    handleVisibilityChange() {
      if (document.hidden) {
        this.pauseAllAudio();
      } else {
        this.updateProgressIndicator(true);
      }
    },
    pauseAllAudio() {
      Object.values(this.$refs).forEach((audio) => {
        if (audio && audio.tagName === "AUDIO") {
          audio.pause();
        }
      });
    },
    openInterface() {
      this.startScreenOpen = true;
      this.isVisibleHelp = false;
    },
    close() {
      this.burger = false;
      this.$emit("close");
    },
    open() {
      this.burger = true;
      this.$emit("open");
      this.sendEvent("burger");
    },
    openRef() {
      this.info = true;
      this.sendEvent("rules");
      this.$emit("open");
    },
    closeRef() {
      this.info = false;
      this.$emit("close");
    },
    updateProgressIndicatorWrapper() {
      if (window.scrollY > 200) {
        this.isVisibleHelp = false;
      }
      this.updateProgressIndicator(false);
    },
    updateProgressIndicator(withoutFade) {
      const { documentElement, body } = document;
      let windowScroll = body.scrollTop || documentElement.scrollTop;
      let height = documentElement.scrollHeight - documentElement.clientHeight;
      if (height == 0) {
        windowScroll = 100;
        height = 10000;
      }
      let progressNumber = (windowScroll / height) * 100;
      this.progressNumber = progressNumber;
      this.progress = (windowScroll / height) * 100 + "%";
      this.updateHandlerText(progressNumber, windowScroll);
      this.updateMusic(progressNumber, withoutFade);
    },
    updateMusic(progress, withoutFade) {
      if (window.innerWidth > 1000) {
        if (!this.isMusicEnabled) {
          this.stopAllAudio();
          return;
        }
        if (this.isDragging) {
          this.stopAllAudio();
          return;
        }
        if (progress < 7) {
          this.playAudio(this.$refs.audio1, withoutFade);
        } else if (progress > 6 && progress < 25) {
          this.playAudio(this.$refs.audio2, withoutFade);
        } else if (progress > 25 && progress < 32) {
          this.playAudio(this.$refs.audio3, withoutFade);
        } else if (progress > 32 && progress < 36) {
          this.playAudio(this.$refs.audio4, withoutFade);
        } else if (progress > 36 && progress < 46) {
          this.playAudio(this.$refs.audio5, withoutFade);
        } else if (progress > 46 && progress < 92) {
          this.playAudio(this.$refs.audio6, withoutFade);
        } else if (progress > 92 && progress < 98.5) {
          this.playAudio(this.$refs.audio8, withoutFade);
        } else if (progress > 98.5 && progress < 100) {
          this.playAudio(this.$refs.audio7, withoutFade);
        }
      } else {
        if (!this.isMusicEnabled) {
          this.stopAllAudio();
          return;
        }
        if (this.isDragging) {
          this.stopAllAudio();
          return;
        }
        this.playAudio(this.$refs.audio1, withoutFade);
      }
    },
    stopAllAudio() {
      Object.values(this.$refs).forEach((audio) => {
        if (audio && audio.tagName === "AUDIO" && audio.id !== "transform") {
          audio.pause();
        }
      });
    },
    toggleMusic() {
      this.isMusicEnabled = !this.isMusicEnabled;
      if (this.isMusicEnabled) {
        this.updateProgressIndicator(true);
      } else {
        this.stopAllAudio();
      }
      this.sendEvent("music");
    },
    playAudio(audioElement, withoutFade) {
      if (withoutFade) {
        Object.values(this.$refs).forEach((audio) => {
          if (
            audio &&
            audio.tagName === "AUDIO" &&
            audio != audioElement &&
            audio.id !== "transform"
          ) {
            audio.pause();
            audio.volume = 1;
          }
        });
        if (audioElement.paused) {
          audioElement.play();
          audioElement.volume = 1;
        }
      } else {
        Object.values(this.$refs).forEach((audio) => {
          if (
            audio &&
            audio.tagName === "AUDIO" &&
            audio != audioElement &&
            audio.id !== "transform"
          ) {
            this.fadeOutAudio(audio);
          }
        });
        if (audioElement && audioElement.paused) {
          this.fadeInAudio(audioElement);
        }
      }
    },
    updateHandlerText(progress) {
      if (window.innerWidth > 1000) {
        if (progress < 7) {
          this.handlerText = "VI век до н.э.";
          this.isMiniHandler = true;
        }
        if (progress >= 7 && progress < 10.5) {
          this.handlerText = "1832";
          this.isMiniHandler = false;
        }
        if (progress >= 10.5 && progress < 17.9) {
          this.handlerText = "1876";
          this.isMiniHandler = false;
        }
        if (progress >= 17.9 && progress < 22.9) {
          this.handlerText = "1880";
          this.isMiniHandler = false;
        }
        if (progress >= 22.9 && progress < 26) {
          this.handlerText = "1895";
          this.isMiniHandler = false;
        }
        if (progress >= 26 && progress < 29) {
          this.handlerText = "1929";
          this.isMiniHandler = false;
        }
        if (progress >= 29 && progress < 31) {
          this.handlerText = "1942";
          this.isMiniHandler = false;
        }
        if (progress >= 31 && progress < 33) {
          this.handlerText = "1956";
          this.isMiniHandler = false;
        }
        if (progress >= 33 && progress < 36) {
          this.handlerText = "1957";
          this.isMiniHandler = false;
        }
        if (progress >= 36 && progress < 40) {
          this.handlerText = "1963";
          this.isMiniHandler = false;
        }
        if (progress >= 40 && progress < 43) {
          this.handlerText = "1969";
          this.isMiniHandler = false;
        }
        if (progress >= 43 && progress < 47) {
          this.handlerText = "1991";
          this.isMiniHandler = false;
        }
        if (progress >= 47 && progress < 50) {
          this.handlerText = "1992";
          this.isMiniHandler = false;
        }
        if (progress >= 50 && progress < 52) {
          this.handlerText = "2009";
          this.isMiniHandler = false;
        }
        if (progress >= 52 && progress < 61) {
          this.handlerText = "2012";
          this.isMiniHandler = false;
        }
        if (progress >= 61 && progress < 68) {
          this.handlerText = "2014";
          this.isMiniHandler = false;
        }
        if (progress >= 68 && progress < 75) {
          this.handlerText = "2015";
          this.isMiniHandler = false;
        }
        if (progress >= 75 && progress < 81) {
          this.handlerText = "2016";
          this.isMiniHandler = false;
        }
        if (progress >= 81 && progress < 84) {
          this.handlerText = "2017";
          this.isMiniHandler = false;
        }
        if (progress >= 84 && progress < 90) {
          this.handlerText = "2018";
          this.isMiniHandler = false;
        }
        if (progress >= 90 && progress < 92) {
          this.handlerText = "2019";
          this.isMiniHandler = false;
        }
        if (progress >= 92 && progress < 94) {
          this.handlerText = "2020";
          this.isMiniHandler = false;
        }
        if (progress >= 94 && progress < 96) {
          this.handlerText = "2022";
          this.isMiniHandler = false;
        }
        if (progress >= 96 && progress <= 98) {
          this.handlerText = "2024";
          this.isMiniHandler = false;
        }
        if (progress >= 98 && progress <= 99) {
          this.handlerText = "2123";
          this.isMiniHandler = false;
        }
      } else {
        if (progress < 7) {
          this.handlerText = "VI век до н.э.";
          this.isMiniHandler = true;
        }
        if (progress >= 7 && progress < 9.5) {
          this.handlerText = "1832";
          this.isMiniHandler = false;
        }
        if (progress >= 9.5 && progress < 16.9) {
          this.handlerText = "1876";
          this.isMiniHandler = false;
        }
        if (progress >= 16.9 && progress < 21) {
          this.handlerText = "1880";
          this.isMiniHandler = false;
        }
        if (progress >= 21 && progress < 23) {
          this.handlerText = "1895";
          this.isMiniHandler = false;
        }
        if (progress >= 23 && progress < 27.4) {
          this.handlerText = "1929";
          this.isMiniHandler = false;
        }
        if (progress >= 27.4 && progress < 30) {
          this.handlerText = "1942";
          this.isMiniHandler = false;
        }
        if (progress >= 30 && progress < 33) {
          this.handlerText = "1956";
          this.isMiniHandler = false;
        }
        if (progress >= 33 && progress < 36) {
          this.handlerText = "1957";
          this.isMiniHandler = false;
        }
        if (progress >= 36 && progress < 39) {
          this.handlerText = "1963";
          this.isMiniHandler = false;
        }
        if (progress >= 39 && progress < 42) {
          this.handlerText = "1969";
          this.isMiniHandler = false;
        }
        if (progress >= 42 && progress < 47) {
          this.handlerText = "1991";
          this.isMiniHandler = false;
        }
        if (progress >= 47 && progress < 50) {
          this.handlerText = "1992";
          this.isMiniHandler = false;
        }
        if (progress >= 50 && progress < 52) {
          this.handlerText = "2009";
          this.isMiniHandler = false;
        }
        if (progress >= 52 && progress < 60) {
          this.handlerText = "2012";
          this.isMiniHandler = false;
        }
        if (progress >= 60 && progress < 68) {
          this.handlerText = "2014";
          this.isMiniHandler = false;
        }
        if (progress >= 68 && progress < 75) {
          this.handlerText = "2015";
          this.isMiniHandler = false;
        }
        if (progress >= 75 && progress < 81) {
          this.handlerText = "2016";
          this.isMiniHandler = false;
        }
        if (progress >= 81 && progress < 84) {
          this.handlerText = "2017";
          this.isMiniHandler = false;
        }
        if (progress >= 84 && progress < 90) {
          this.handlerText = "2018";
          this.isMiniHandler = false;
        }
        if (progress >= 90 && progress < 92) {
          this.handlerText = "2019";
          this.isMiniHandler = false;
        }
        if (progress >= 92 && progress < 94) {
          this.handlerText = "2020";
          this.isMiniHandler = false;
        }
        if (progress >= 94 && progress < 96) {
          this.handlerText = "2022";
          this.isMiniHandler = false;
        }
        if (progress >= 96 && progress <= 98) {
          this.handlerText = "2024";
          this.isMiniHandler = false;
        }
        if (progress >= 98 && progress <= 99) {
          this.handlerText = "2123";
          this.isMiniHandler = false;
        }
      }
    },
    startDrag(e) {
      this.$emit("stopautoscroll");
      this.stopAllAudio();
      this.isDragging = true;
      if (this.isMusicEnabled) {
        this.audioElement.play();
      }
      this.startClientX = e.type === "touchstart" ? e.touches[0].clientX : e.clientX;
      this.startScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      document.addEventListener("mousemove", this.handleDrag);
      document.addEventListener("mouseup", this.stopDrag);
      document.addEventListener("touchmove", this.handleDrag);
      document.addEventListener("touchend", this.stopDrag);
    },
    handleDrag(e) {
      if (!this.isDragging) return;

      const currentClientX = e.type === "touchmove" ? e.touches[0].clientX : e.clientX;
      const deltaX = currentClientX - this.startClientX;

      const scrollHeight =
        document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const newScrollTop =
        this.startScrollTop + (deltaX / this.$el.clientWidth) * scrollHeight;

      document.documentElement.scrollTop = document.body.scrollTop = newScrollTop;
    },
    stopDrag() {
      if (!this.isDragging) return;
      if (this.isMusicEnabled) {
        this.audioElement.pause();
      }

      this.isDragging = false;
      this.updateProgressIndicator(false);
      document.removeEventListener("mousemove", this.handleDrag);
      document.removeEventListener("mouseup", this.stopDrag);
      document.removeEventListener("touchmove", this.handleDrag);
      document.removeEventListener("touchend", this.stopDrag);
    },
    fadeOutAudio(audioElement, callback) {
      let volume = audioElement.volume;
      let interval = setInterval(() => {
        if (volume > 0.1) {
          volume -= 0.1;
          volume = Math.max(0, Math.min(1, volume));
          audioElement.volume = volume;
        } else {
          clearInterval(interval);
          audioElement.pause();
          if (callback) callback();
        }
      }, 200);
    },
    fadeInAudio(audioElement) {
      let volume = 0;
      audioElement.play();
      let interval = setInterval(() => {
        if (volume < 1) {
          volume += 0.1;
          volume = Math.max(0, Math.min(1, volume));
          audioElement.volume = volume;
        } else {
          clearInterval(interval);
        }
      }, 200);
    },
  },
  mounted() {
    this.audioElement = this.$refs.audioElement;
    window.addEventListener("scroll", this.updateProgressIndicatorWrapper);
    this.$el.querySelector("span").addEventListener("mousedown", this.startDrag);
    this.$el.querySelector("span").addEventListener("touchstart", this.startDrag);
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
    setTimeout(() => {
      this.isVisibleHelp = true;
    }, 200);
    if (window.innerWidth < 1000) {
      this.isMobile = true;
    }
  },
  watch: {
    sound() {
      //this.isMusicEnabled = true;
      this.updateProgressIndicator(false);
    },
    modal(val) {
      if (this.activeContent && this.activeContent.is_disable_music) {
        if (val == true) {
          setTimeout(() => {
            Object.values(this.$refs).forEach((audio) => {
              if (audio && audio.tagName === "AUDIO" && audio.id !== "transform") {
                this.fadeOutAudio(audio);
              }
            });
          }, 10);
        } else {
          this.updateProgressIndicator(true);
          setTimeout(() => {
            this.updateProgressIndicator(true);
          }, 6000);
        }
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
  },
};
</script>

<style lang="scss">
body::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1; // hide images
  content: url(../assets/img/play-bg-hover.svg) url(../assets/img/play-bg-click.svg)
    url(../assets/img/info-bg-hover.svg) url(../assets/img/info-bg-click.svg)
    url(../assets/img/menu-bg-hover.svg) url(../assets/img/menu-bg-click.svg)
    url(../assets/img/sound-bg-hover.svg) url(../assets/img/sound-bg-click.svg)
    url(../assets/img/info-hover.svg) url(../assets/img/games-hover.svg)
    url(../assets/img/3d-cube-hover.svg) url(../assets/img/close-border-hover.png)
    url(../assets/img/close-border-click.png); // load images
}
</style>
<style lang="sass">
@import '@/assets/sass/functions.sass'
.play-button
	width: vw(50px)
	height: vw(40px)
	background-image: url(../assets/img/play-bg-2.svg)
	background-size: vw(32px)
	background-color: #FF4F12
	position: absolute
	z-index: 999999999999999
	background-repeat: no-repeat
	background-position: 50% 50%
	bottom: -1px
	cursor: pointer
	cursor: url('../assets/img/cursor-active-2.svg') 0 0, auto !important
	pointer-events: auto
	left: -1px
	transition: all 300ms
	@media screen and (max-width: 1000px)
		width: vwm(50px)
		height: vwm(40px)
		background-size: vwm(32px)
		transition: none
	&:hover
		background-color: rgba(255, 79, 18, 0.8)
		// background-image: url(../assets/img/play-bg-hover.svg)
	&:active
		background-color: #FF4F12
		// background-image: url(../assets/img/play-bg-click.svg)
	&--pause
		background-image: url(../assets/img/pause-bg-2.svg)
		background-position: 50% 50%
		&:hover
			background-color: rgba(255, 79, 18, 0.8)
			background-image: url(../assets/img/pause-bg-hover-2.svg)
		&:active
			background-color: #FF4F12
			// background-image: url(../assets/img/pause-bg-active.svg)
.invisible-handler
	display: block
	width: vw(100px)
	height: vw(100px)
	cursor: pointer
	right: vw(-99px)
	position: absolute
	cursor: url('../assets/img/cursor-pointer-2.png') 0 0, auto !important
	margin-top: vw(-20px)
	z-index: 500
	.space
		position: absolute
		width: vw(100px)
		height: vw(100px)
		left: vw(-100px)
	@media screen and (max-width: 1000px)
		width: vwm(100px)
		height: vwm(100px)
		right: vwm(-99px)
		margin-top: vwm(-14px)
.interface
	position: fixed
	z-index: 100
	top: 0
	left: 0
	right: 0
	bottom: 0
	margin: auto
	pointer-events: none
	&__line
		// padding-left: 3.4vw
		// @media screen and (max-width: 1000px)
		// 	padding-left: vwm(49px)
	&__info
		position: absolute
		top: 0
		left: 0
		bottom: 0
		right: 0
		margin: auto
		width: vw(434px)
		height: vw(295px)
		border: 1px solid #FF4F12
		background: rgba(25, 0, 53, 0.70)
		border-radius: 2px
		backdrop-filter: blur(10px)
		font-size: vw(16px)
		color: #FFFFFF
		padding-top: vw(61px)
		text-align: center
		padding-left: vw(20px)
		padding-right: vw(20px)
		z-index: -1
		@media screen and (max-width: 1000px)
			width: 100vw
			height: 100%
			background: transparent
			position: fixed
			display: flex
			justify-content: center
			align-items: center
			flex-direction: column
			border-radius: 0
			backdrop-filter: none
			font-size: vwm(17px)
			padding-left: vwm(40px)
			padding-right: vwm(40px)
			padding-top: vwm(32px)
		p
			margin-top: vw(111px)
			@media screen and (max-width: 1000px)
				margin-top: 0
		.mobile-only
			display: none
			margin-bottom: 0
			margin-top: vwm(20px) !important
			pointer-events: auto
			@media screen and (max-width: 1000px)
				display: block
		.mob
			display: none
			@media screen and (max-width: 1000px)
				display: block
		.desk
			display: block
			@media screen and (max-width: 1000px)
				display: none
		&-control
			position: relative
		&-image
			position: absolute
			left: 0
			right: 0
			margin: auto
			@media screen and (max-width: 1000px)
				position: relative
				margin: 0
			&.mobile
				display: none
				@media screen and (max-width: 1000px)
					display: block
					width: vwm(56.1px)
					margin-bottom: vwm(30px)
					margin-left: vwm(-6px)
				&:after
					background-image: url(../assets/img/hand.svg)
					width: vwm(50px)
					height: vwm(64px)
					top: vwm(37px)
					left: vwm(11px)
			&.desktop
				@media screen and (max-width: 1000px)
					display: none
			&.mouse
				margin-left: vw(-18px)
				animation: change1
				animation-duration: 3s
				animation-iteration-count: infinite
				img
					width: vw(45px)
				&:after
					top: vw(28px)
					left: vw(29px)
					background-image: url(../assets/img/hand.svg)
			&.touchpad
				animation: change2
				animation-duration: 3s
				animation-iteration-count: infinite
				img
					width: vw(76px)
				&:after
					top: vw(29px)
					left: vw(22px)
					background-image: url(../assets/img/hand-2.svg)
			&:after
				content: ''
				width: vw(50px)
				height: vw(64px)
				position: absolute
				background-size: contain
				background-repeat: no-repeat
				display: block
				animation: upDown
				animation-duration: 2s
				animation-iteration-count: infinite
				left: 0
				right: 0
				margin: auto
	&__intro
		pointer-events: auto
	&__bg
		display: none
		@media screen and (max-width: 1000px)
			display: block
			width: 100vw
			height: 100vh
			height: var(--app-height)
			background-color: rgba(25, 0, 53, 0.84)
			-webkit-backdrop-filter: blur(10px)
			backdrop-filter: blur(10px)
			position: absolute
			top: 0
			left: 0
	&__box
		width: vw(1380px)
		height: 91vh
		border: 1px solid #FF4F12
		border-radius: 2px
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		margin: auto
		z-index: 200
		@media screen and (max-width: 1000px)
			width: 94%
			height: 97%
	&__day-night
		width: vw(50px)
		height: vw(40px)
		position: absolute
		left: 0
		right: 0
		background-image: url(../assets/img/day-night.svg)
		background-size: contain
		top: -1px
		left: -1px
		cursor: pointer
		background-repeat: no-repeat
		@media screen and (max-width: 1000px)
			width: vwm(50px)
			height: vwm(40px)
	&__button
		color: #fff
		padding: vw(15px) vw(50px)
		border: 1px solid #FF4F12
		background: rgba(255, 79, 18, 0.10)
		-webkit-backdrop-filter: blur(2.5px)
		backdrop-filter: blur(2.5px)
		text-transform: uppercase
		font-size: vw(16px)
		letter-spacing: vw(0.8px)
		font-weight: 500
		margin-top: vw(-2px)
		transition: all 300ms
		font-family: 'Tektur'
		&--2
			width: vw(254px)
			padding: 0
			height: vw(50px)
			display: block
			margin: auto
			margin-top: vw(20px)
			line-height: vw(50px)
			text-align: center
		&:hover
			background: rgba(255, 79, 18, 0.40)
	&__menu
		width: vw(50px)
		height: vw(40px)
		position: absolute
		background: #FF4F12
		top: -1px
		right: -1px
		cursor: pointer
		cursor: url('../assets/img/cursor-active-2.svg') 0 0, auto !important
		background-repeat: no-repeat
		border: 1px solid rgba(255, 79, 18, 0.80)
		border-radius: 0 2px 0 2px
		transition: all 300ms
		@media screen and (max-width: 1000px)
			width: vwm(50px)
			height: vwm(40px)
		&:after
			content: ''
			width: vw(32px)
			height: vw(32px)
			background-size: contain
			position: absolute
			background-repeat: no-repeat
			top: 0
			bottom: 0
			right: 0
			left: 0
			margin: auto
			background-image: url(../assets/img/menu-bg-2.svg)
			@media screen and (max-width: 1000px)
				width: vwm(32px)
				height: vwm(32px)
		&:hover
			background: rgba(255, 79, 18, 0.8)
			&:after
				background-image: url(../assets/img/menu-bg-hover-2.svg)
		&:active
			background: #FF4F12
			&:after
				background-image: url(../assets/img/menu-bg-click-2.svg)
	&__line
		width: vw(1264px)
		left: vw(57px)
		height: vw(40.04px)
		border: 1px solid rgba(255, 79, 18, 0.80)
		background-color: rgba(255, 79, 18, 0.10)
		webkit-backdrop-filter: blur(10px)
		backdrop-filter: blur(10px)
		bottom: -1px
		position: absolute
		border-right: none
		cursor: url('../assets/img/cursor_3.svg') 0 0, auto !important
		@media screen and (max-width: 1000px)
			height: vwm(40px)
			width: vwm(238px)
			left: vwm(57px)
			bottom: -1px
			border-top: none
			border-left: none
			//backdrop-filter: none
		span
			display: block
			width: vw(843px)
			height: 100%
			position: relative
			background: linear-gradient(270deg, #4A00E7 0%, #FF4F12 100%)
		.hanlder
			width: vw(91px)
			height: vw(47px)
			background-image: url(../assets/img/handler.svg)
			background-size: contain
			background-repeat: no-repeat
			color: #70F
			text-align: center
			font-family: 'Tektur'
			font-weight: 600
			font-size: vw(23px)
			top: vw(-16px)
			padding-top: vw(3px)
			left: vw(-44px)
			position: absolute
			-webkit-user-select: none
			cursor: url('../assets/img/cursor-pointer-2.png') 0 0, auto !important
			&.mini
				font-size: vw(15px)
				padding-left: vw(13px)
				line-height: 1
				padding-top: vw(6px)
				padding-right: vw(13px)
				left: vw(0px) !important
				background-image: url(../assets/img/handler-left.svg)
				line-height: 0.8
				@media screen and (max-width: 1000px)
					font-size: vwm(12px)
					padding-left: vwm(10px)
					line-height: 1
					padding-top: vwm(4px)
					padding-right: vwm(10px)
					left: vwm(0px)
			@media screen and (max-width: 1000px)
				width: vwm(89px)
				height: vwm(47px)
				font-size: vwm(22px)
				top: vwm(-22px)
				left: vwm(-44px) !important
				padding-top: vwm(3px)
		.move
			width: vw(44px)
			height: vw(20px)
			background-image: url(../assets/img/move.svg)
			position: absolute
			background-size: contain
			left: vw(820px)
			top: vw(19px)
			background-repeat: no-repeat
			@media screen and (max-width: 1000px)
				display: none
	&__sound
		width: vw(50px)
		height: vw(40px)
		position: absolute
		background: #FF4F12
		background-size: contain
		bottom: -1px
		right: -1px
		cursor: pointer
		cursor: url('../assets/img/cursor-active-2.svg') 0 0, auto !important
		background-repeat: no-repeat
		border: 1px solid rgba(255, 79, 18, 0.80)
		border-radius: 0 0 2px 0
		transition: all 300ms
		@media screen and (max-width: 1000px)
			width: vwm(50px)
			height: vwm(40px)
		&:after
			content: ''
			width: vw(32px)
			height: vw(32px)
			background-size: contain
			position: absolute
			background-repeat: no-repeat
			top: 0
			bottom: 0
			right: 0
			left: 0
			margin: auto
			background-image: url(../assets/img/sound-bg-2.svg)
			@media screen and (max-width: 1000px)
				width: vwm(32px)
				height: vwm(32px)
		&:hover
			background: rgba(255, 79, 18, 0.8)
			&:after
				background-image: url(../assets/img/sound-bg-hover-2.svg)
		&:active
			background: #FF4F12
			&:after
				background-image: url(../assets/img/sound-bg-click-2.svg)
		&--disabled
			&:after
				background-image: url(../assets/img/sound-bg-disabled-2.svg)
			&:hover
				background: rgba(255, 79, 18, 0.8)
				&:after
					ackground-image: url(../assets/img/sound-bg-hover-2.svg)
			&:active
				background: #FF4F12
				&:after
					background-image: url(../assets/img/sound-bg-click-2.svg)
	&__back
		position: absolute
		bottom: vw(80px)
		right: vw(14px)
		cursor: pointer
		cursor: url('../assets/img/cursor-active-2.svg') 0 0, auto !important
		@media screen and (max-width: 1000px)
			bottom: vwm(80px)
			right: vwm(11px)
		&-icon
			width: vw(74px)
			height: vw(74px)
			background-color: #fff
			border: vw(4px) solid #FF4F12
			border-radius: 50%
			margin: auto
			animation: SmallToLarge 1s linear 0s infinite !important
			position: relative
			@media screen and (max-width: 1000px)
				width: vwm(74px)
				height: vwm(74px)
				border-width: vwm(4px)
			&:after
				content: ''
				background-color: rgba(255, 255, 255, 0.2)
				width: vw(100px)
				height: vw(100px)
				position: absolute
				left: 50%
				top: 50%
				transform: translate(-50%, -50%)
				border-radius: 50%
				z-index: -1
				@media screen and (max-width: 1000px)
					width: vwm(100px)
					height: vwm(100px)
			&:before
				content: ''
				background-image: url(../assets/img/interface-back-icon.png)
				width: vw(50px)
				height: vw(50px)
				background-size: contain
				background-repeat: no-repeat
				position: absolute
				left: 50%
				top: 50%
				transform: translate(-50%, -50%)
				z-index: 10
				@media screen and (max-width: 1000px)
					width: vwm(50px)
					height: vwm(50px)
		&-title
			font-size: vw(14.7px)
			line-height: vw(19px)
			color: #fff
			font-weight: 600
			font-family: 'Tektur'
			text-transform: uppercase
			text-align: center
			background-color: #FF4F12
			border-radius: vw(7px)
			padding: vw(1px) vw(9px) vw(3px) vw(9px)
			margin-top: vw(8px)
			position: relative
			z-index: 20
			@media screen and (max-width: 1000px)
				font-size: vwm(11px)
				line-height: vwm(14.3px)
				border-radius: vwm(7px)
				padding: vwm(3px) vwm(9px) vwm(3px) vwm(9px)
				margin-top: vwm(8px)
	&__ref
		width: vw(50px)
		height: vw(40px)
		position: absolute
		background: #FF4F12
		background-size: contain
		top: -1px
		left: -1px
		cursor: pointer
		cursor: url('../assets/img/cursor-active-2.svg') 0 0, auto !important
		border: 1px solid rgba(255, 79, 18, 0.80)
		border-radius: 0 0 2px 0
		transition: all 300ms
		z-index: 10
		@media screen and (max-width: 1000px)
			width: vwm(50px)
			height: vwm(40px)
		&:after
			content: ''
			width: vw(32px)
			height: vw(32px)
			background-size: contain
			position: absolute
			background-repeat: no-repeat
			top: 0
			bottom: 0
			right: 0
			left: 0
			margin: auto
			background-image: url(../assets/img/info-bg-2.svg)
			transition: all 300ms
			@media screen and (max-width: 1000px)
				width: vwm(32px)
				height: vwm(32px)
		&:hover
			background: rgba(255, 79, 18, 0.8)
			// &:after
			// 	background-image: url(../assets/img/info-bg-hover.svg)
		&:active
			background: #FF4F12
			// &:after
			// 	background-image: url(../assets/img/info-bg-click.svg)
	&__text
		width: vw(528px)
		font-size: vw(16px)
		color: #fff
		text-align: center
		margin: auto
		margin-top: vw(9px)
		line-height: 1.3
	&__title
		font-size: vw(42px)
		font-weight: 500
		font-family: 'Tektur'
		color: #fff
		text-align: center
		padding-top: vw(213px)
	&__hint
		color: #FFB49A
		font-size: vw(13px)
		position: absolute
		left: vw(148px)
		top: vw(74px)
		span
			position: absolute
			width: vw(65px)
			height: vw(46px)
			background-image: url(../assets/img/arrow-1.svg)
			display: block
			background-size: contain
			background-repeat: no-repeat
			left: vw(-79px)
			top: vw(-51px)
		&--2
			left: vw(1012px)
			span
				transform: scale(-1, 1)
				left: vw(236px)
		&--3
			left: vw(59px)
			top: vw(304px)
			span
				background-image: url(../assets/img/arrow-2.svg)
				width: vw(77px)
				height: vw(51px)
				left: vw(160px)
				top: vw(56px)
		&--4
			left: vw(571px)
			top: vw(506px)
			span
				background-image: url(../assets/img/arrow-2.svg)
				width: vw(77px)
				height: vw(51px)
				left: vw(174px)
				top: vw(44px)
		&--5
			left: vw(1040px)
			top: vw(517px)
			span
				background-image: url(../assets/img/arrow-2.svg)
				width: vw(77px)
				height: vw(51px)
				left: vw(216px)
				top: vw(59px)



@keyframes loading
	0%
		width: 0%
	100%
		width: 100%
@keyframes upDown
	0%
		transform: translateY(0)
	50%
		transform: translateY(0.4vw)
	100%
		transform: translateY(0)
@media screen and (max-width: 1000px)
	@keyframes upDown
		0%
			transform: translateY(0)
		50%
			transform: translateY(3vw)
		100%
			transform: translateY(0)
@keyframes change1
	0%
		opacity: 1
	50%
		opacity: 1
	60%
		opacity: 0
	100%
		opacity: 0
@keyframes change2
	0%
		opacity: 0
	50%
		opacity: 0
	60%
		opacity: 1
	100%
		opacity: 1
</style>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
