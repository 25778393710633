<template>
  <div class="burger" data-lenis-prevent data-scroll-lock-scrollable>
    <div class="burger__box" data-scroll-lock-scrollable>
      <div class="burger__close" @click="$emit('close')"></div>
      <div class="burger__logo"><img src="@/assets/img/logo-2.svg" alt="" /></div>
      <div class="burger__menu" v-if="isMobile" data-scroll-lock-scrollable>
        <div class="burger__menu-item">
          <div
            class="burger__menu-head"
            :class="{ active: opened == 1 }"
            @click="openSection(1)"
          >
            <div class="burger__menu-arrow" :class="{ active: opened == 1 }"></div>
            <div class="burger__menu-label">Прошлое</div>
          </div>
          <div
            class="burger__menu-dropdown"
            v-if="opened == 1"
            id="section_mob_1"
            data-scroll-lock-scrollable
          >
            <div
              class="burger__menu-dropdown-item"
              @click="open(1000)"
              :class="{ active: handlerText == 'VI век до н.э.' }"
            >
              <div class="burger__menu-dropdown-text">VI век до н.э.</div>
              <div class="burger__menu-dropdown-text">Первые почтовые системы</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(5050)"
              :class="{ active: handlerText == '1832' }"
            >
              <div class="burger__menu-dropdown-text">1832</div>
              <div class="burger__menu-dropdown-text">
                Первый в мире электромагнитный телеграф
              </div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(7050)"
              :class="{ active: handlerText == '1876' }"
            >
              <div class="burger__menu-dropdown-text">1876</div>
              <div class="burger__menu-dropdown-text">Первый в мире телефон</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(12000)"
              :class="{ active: handlerText == '1880' }"
            >
              <div class="burger__menu-dropdown-text">1880</div>
              <div class="burger__menu-dropdown-text">Первый в мире таксофон</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(15000)"
              :class="{ active: handlerText == '1895' }"
            >
              <div class="burger__menu-dropdown-text">1895</div>
              <div class="burger__menu-dropdown-text">Первый в мире радиоприёмник</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(17200)"
              :class="{ active: handlerText == '1929' }"
            >
              <div class="burger__menu-dropdown-text">1929</div>
              <div class="burger__menu-dropdown-text">Первая в СССР машинная АТС</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(19100)"
              :class="{ active: handlerText == '1942' }"
            >
              <div class="burger__menu-dropdown-text">1942</div>
              <div class="burger__menu-dropdown-text">
                Музыкальные трансляции в блокадном Ленинграде
              </div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(21000)"
              :class="{ active: handlerText == '1956' }"
            >
              <div class="burger__menu-dropdown-text">1956</div>
              <div class="burger__menu-dropdown-text">Первый в мире пейджер</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(23000)"
              :class="{ active: handlerText == '1957' }"
            >
              <div class="burger__menu-dropdown-text">1957</div>
              <div class="burger__menu-dropdown-text">
                Первый искусственный спутник Земли
              </div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(25000)"
              :class="{ active: handlerText == '1963' }"
            >
              <div class="burger__menu-dropdown-text">1963</div>
              <div class="burger__menu-dropdown-text">
                Первая автоматическая мобильная связь в СССР
              </div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(27100)"
              :class="{ active: handlerText == '1969' }"
            >
              <div class="burger__menu-dropdown-text">1969</div>
              <div class="burger__menu-dropdown-text">Появление интернета</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(29000)"
              :class="{ active: handlerText == '1991' }"
            >
              <div class="burger__menu-dropdown-text">1991</div>
              <div class="burger__menu-dropdown-text">
                Первый звонок по сотовому телефону
              </div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(32900)"
              :class="{ active: handlerText == '1992' }"
            >
              <div class="burger__menu-dropdown-text">1992</div>
              <div class="burger__menu-dropdown-text">Первый сайт в доменной зоне RU</div>
            </div>
          </div>
        </div>
        <div class="burger__menu-item">
          <div
            class="burger__menu-head"
            :class="{ active: opened == 2 }"
            @click="openSection(2)"
          >
            <div class="burger__menu-arrow" :class="{ active: opened == 2 }"></div>
            <div class="burger__menu-label">Настоящее</div>
          </div>
          <div
            class="burger__menu-dropdown"
            v-if="opened == 2"
            id="section_2_mob"
            data-scroll-lock-scrollable
          >
            <div
              class="burger__menu-dropdown-item"
              @click="open(35000)"
              :class="{ active: handlerText == '2009' }"
            >
              <div class="burger__menu-dropdown-text">2009</div>
              <div class="burger__menu-dropdown-text">Госуслуги</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(35600)"
              :class="{ active: handlerText == '2012' && scrollPosition < 36900 }"
            >
              <div class="burger__menu-dropdown-text">2012</div>
              <div class="burger__menu-dropdown-text">
                Национальная облачная платформа
              </div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(36900)"
              :class="{ active: handlerText == '2012' && scrollPosition > 36899 }"
            >
              <div class="burger__menu-dropdown-text">2012</div>
              <div class="burger__menu-dropdown-text">Цифровые выборы</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(43000)"
              :class="{
                active:
                  handlerText == '2014' &&
                  scrollPosition > 42999 &&
                  scrollPosition < 45000,
              }"
            >
              <div class="burger__menu-dropdown-text">2014</div>
              <div class="burger__menu-dropdown-text">Смотри.ЕГЭ</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(45000)"
              :class="{ active: handlerText == '2014' && scrollPosition > 44999 }"
            >
              <div class="burger__menu-dropdown-text">2014</div>
              <div class="burger__menu-dropdown-text">Большие данные</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(46000)"
              :class="{ active: handlerText == '2015' }"
            >
              <div class="burger__menu-dropdown-text">2015</div>
              <div class="burger__menu-dropdown-text">Умный город</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(50900)"
              :class="{ active: handlerText == '2016' && scrollPosition < 53800 }"
            >
              <div class="burger__menu-dropdown-text">2016</div>
              <div class="burger__menu-dropdown-text">ОС “Аврора”</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(53800)"
              :class="{ active: handlerText == '2016' && scrollPosition > 53799 }"
            >
              <div class="burger__menu-dropdown-text">2016</div>
              <div class="burger__menu-dropdown-text">Кибербезопасность</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(54700)"
              :class="{ active: handlerText == '2017' }"
            >
              <div class="burger__menu-dropdown-text">2017</div>
              <div class="burger__menu-dropdown-text">Искусственный интеллект</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(57000)"
              :class="{ active: handlerText == '2018' && scrollPosition < 59000 }"
            >
              <div class="burger__menu-dropdown-text">2018</div>
              <div class="burger__menu-dropdown-text">Единая биометрическая система</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(59000)"
              :class="{ active: handlerText == '2018' && scrollPosition > 58999 }"
            >
              <div class="burger__menu-dropdown-text">2018</div>
              <div class="burger__menu-dropdown-text">WINK</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(60900)"
              :class="{ active: handlerText == '2019' }"
            >
              <div class="burger__menu-dropdown-text">2019</div>
              <div class="burger__menu-dropdown-text">Ростелеком Лицей</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(62050)"
              :class="{ active: handlerText == '2020' && scrollPosition < 63000 }"
            >
              <div class="burger__menu-dropdown-text">2020</div>
              <div class="burger__menu-dropdown-text">Ростелеком Ключ</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(63000)"
              :class="{ active: handlerText == '2020' && scrollPosition > 62999 }"
            >
              <div class="burger__menu-dropdown-text">2020</div>
              <div class="burger__menu-dropdown-text">Голосовой помощник Маруся</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(63800)"
              :class="{ active: handlerText == '2022' }"
            >
              <div class="burger__menu-dropdown-text">2022</div>
              <div class="burger__menu-dropdown-text">
                Устранение цифрового неравенства
              </div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(67000)"
              :class="{ active: handlerText == '2024' }"
            >
              <div class="burger__menu-dropdown-text">2024</div>
              <div class="burger__menu-dropdown-text">ИИ: Будущее наступает</div>
            </div>
          </div>
        </div>
        <div class="burger__menu-item">
          <div
            class="burger__menu-head"
            :class="{ active: opened == 3 }"
            @click="openSection(3)"
            data-scroll-lock-scrollable
          >
            <div class="burger__menu-arrow" :class="{ active: opened == 3 }"></div>
            <div class="burger__menu-label">Будущее</div>
          </div>
          <div class="burger__menu-dropdown" v-if="opened == 3">
            <div class="burger__menu-dropdown-item" @click="open(68000)">
              <div class="burger__menu-dropdown-text"></div>
              <div class="burger__menu-dropdown-text">Примерь роль футуролога</div>
            </div>
            <div class="burger__menu-dropdown-item" @click="open(68000)">
              <div class="burger__menu-dropdown-text"></div>
              <div class="burger__menu-dropdown-text">Вопросы нейросети будущего</div>
            </div>
          </div>
        </div>
      </div>
      <div class="burger__menu" v-else>
        <div class="burger__menu-item">
          <div
            class="burger__menu-head"
            :class="{ active: opened == 1 }"
            @click="openSection(1)"
          >
            <div class="burger__menu-arrow" :class="{ active: opened == 1 }"></div>
            <div class="burger__menu-label">Прошлое</div>
          </div>
          <div class="burger__menu-dropdown" v-if="opened == 1" id="section_1">
            <div
              class="burger__menu-dropdown-item"
              @click="open(1000)"
              :class="{ active: handlerText == 'VI век до н.э.' }"
            >
              <div class="burger__menu-dropdown-text">VI век до н.э.</div>
              <div class="burger__menu-dropdown-text">Первые почтовые системы</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(5000)"
              :class="{ active: handlerText == '1832' }"
            >
              <div class="burger__menu-dropdown-text">1832</div>
              <div class="burger__menu-dropdown-text">
                Первый в мире электромагнитный телеграф
              </div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(7000)"
              :class="{ active: handlerText == '1876' }"
            >
              <div class="burger__menu-dropdown-text">1876</div>
              <div class="burger__menu-dropdown-text">Первый в мире телефон</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(12000)"
              :class="{ active: handlerText == '1880' }"
            >
              <div class="burger__menu-dropdown-text">1880</div>
              <div class="burger__menu-dropdown-text">Первый в мире таксофон</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(15000)"
              :class="{ active: handlerText == '1895' }"
            >
              <div class="burger__menu-dropdown-text">1895</div>
              <div class="burger__menu-dropdown-text">Первый в мире радиоприёмник</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(18000)"
              :class="{ active: handlerText == '1929' }"
            >
              <div class="burger__menu-dropdown-text">1929</div>
              <div class="burger__menu-dropdown-text">Первая в СССР машинная АТС</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(19000)"
              :class="{ active: handlerText == '1942' }"
            >
              <div class="burger__menu-dropdown-text">1942</div>
              <div class="burger__menu-dropdown-text">
                Музыкальные трансляции в блокадном Ленинграде
              </div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(21000)"
              :class="{ active: handlerText == '1956' }"
            >
              <div class="burger__menu-dropdown-text">1956</div>
              <div class="burger__menu-dropdown-text">Первый в мире пейджер</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(23000)"
              :class="{ active: handlerText == '1957' }"
            >
              <div class="burger__menu-dropdown-text">1957</div>
              <div class="burger__menu-dropdown-text">
                Первый искусственный спутник Земли
              </div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(25000)"
              :class="{ active: handlerText == '1963' }"
            >
              <div class="burger__menu-dropdown-text">1963</div>
              <div class="burger__menu-dropdown-text">
                Первая автоматическая мобильная связь в СССР
              </div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(28000)"
              :class="{ active: handlerText == '1969' }"
            >
              <div class="burger__menu-dropdown-text">1969</div>
              <div class="burger__menu-dropdown-text">Появление интернета</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(29000)"
              :class="{ active: handlerText == '1991' }"
            >
              <div class="burger__menu-dropdown-text">1991</div>
              <div class="burger__menu-dropdown-text">
                Первый звонок по сотовому телефону
              </div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(33000)"
              :class="{ active: handlerText == '1992' }"
            >
              <div class="burger__menu-dropdown-text">1992</div>
              <div class="burger__menu-dropdown-text">Первый сайт в доменной зоне RU</div>
            </div>
          </div>
        </div>
        <div class="burger__menu-item">
          <div
            class="burger__menu-head"
            :class="{ active: opened == 2 }"
            @click="openSection(2)"
          >
            <div class="burger__menu-arrow" :class="{ active: opened == 2 }"></div>
            <div class="burger__menu-label">Настоящее</div>
          </div>
          <div class="burger__menu-dropdown" v-if="opened == 2" id="section_2">
            <div
              class="burger__menu-dropdown-item"
              @click="open(35000)"
              :class="{ active: handlerText == '2009' }"
            >
              <div class="burger__menu-dropdown-text">2009</div>
              <div class="burger__menu-dropdown-text">Госуслуги</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(35500)"
              :class="{ active: handlerText == '2012' && scrollPosition < 37000 }"
            >
              <div class="burger__menu-dropdown-text">2012</div>
              <div class="burger__menu-dropdown-text">
                Национальная облачная платформа
              </div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(37000)"
              :class="{ active: handlerText == '2012' && scrollPosition > 37000 }"
            >
              <div class="burger__menu-dropdown-text">2012</div>
              <div class="burger__menu-dropdown-text">Цифровые выборы</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(43000)"
              :class="{
                active:
                  handlerText == '2014' &&
                  scrollPosition > 37000 &&
                  scrollPosition < 45000,
              }"
            >
              <div class="burger__menu-dropdown-text">2014</div>
              <div class="burger__menu-dropdown-text">Смотри.ЕГЭ</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(45000)"
              :class="{ active: handlerText == '2014' && scrollPosition > 45000 }"
            >
              <div class="burger__menu-dropdown-text">2014</div>
              <div class="burger__menu-dropdown-text">Большие данные</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(46200)"
              :class="{ active: handlerText == '2015' }"
            >
              <div class="burger__menu-dropdown-text">2015</div>
              <div class="burger__menu-dropdown-text">Умный город</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(50000)"
              :class="{ active: handlerText == '2016' && scrollPosition < 54000 }"
            >
              <div class="burger__menu-dropdown-text">2016</div>
              <div class="burger__menu-dropdown-text">ОС “Аврора”</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(54000)"
              :class="{ active: handlerText == '2016' && scrollPosition > 53999 }"
            >
              <div class="burger__menu-dropdown-text">2016</div>
              <div class="burger__menu-dropdown-text">Кибербезопасность</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(54050)"
              :class="{ active: handlerText == '2017' }"
            >
              <div class="burger__menu-dropdown-text">2017</div>
              <div class="burger__menu-dropdown-text">Искусственный интеллект</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(56900)"
              :class="{ active: handlerText == '2018' && scrollPosition < 59000 }"
            >
              <div class="burger__menu-dropdown-text">2018</div>
              <div class="burger__menu-dropdown-text">Единая биометрическая система</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(59000)"
              :class="{ active: handlerText == '2018' && scrollPosition > 58999 }"
            >
              <div class="burger__menu-dropdown-text">2018</div>
              <div class="burger__menu-dropdown-text">WINK</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(61000)"
              :class="{ active: handlerText == '2019' }"
            >
              <div class="burger__menu-dropdown-text">2019</div>
              <div class="burger__menu-dropdown-text">Ростелеком Лицей</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(62100)"
              :class="{ active: handlerText == '2020' && scrollPosition < 63050 }"
            >
              <div class="burger__menu-dropdown-text">2020</div>
              <div class="burger__menu-dropdown-text">Ростелеком Ключ</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(63050)"
              :class="{ active: handlerText == '2020' && scrollPosition > 63049 }"
            >
              <div class="burger__menu-dropdown-text">2020</div>
              <div class="burger__menu-dropdown-text">Голосовой помощник Маруся</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(64000)"
              :class="{ active: handlerText == '2022' }"
            >
              <div class="burger__menu-dropdown-text">2022</div>
              <div class="burger__menu-dropdown-text">
                Устранение цифрового неравенства
              </div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(67000)"
              :class="{ active: handlerText == '2024' }"
            >
              <div class="burger__menu-dropdown-text">2024</div>
              <div class="burger__menu-dropdown-text">ИИ: Будущее наступает</div>
            </div>
          </div>
        </div>
        <div class="burger__menu-item">
          <div
            class="burger__menu-head"
            :class="{ active: opened == 3 }"
            @click="openSection(3)"
          >
            <div class="burger__menu-arrow" :class="{ active: opened == 3 }"></div>
            <div class="burger__menu-label">Будущее</div>
          </div>
          <div class="burger__menu-dropdown" v-if="opened == 3">
            <div
              class="burger__menu-dropdown-item"
              @click="open(69100)"
              :class="{ active: handlerText == '2123' && scrollPosition < 66550 }"
            >
              <div class="burger__menu-dropdown-text"></div>
              <div class="burger__menu-dropdown-text">Примерь роль футуролога</div>
            </div>
            <div
              class="burger__menu-dropdown-item"
              @click="open(69100)"
              :class="{ active: handlerText == '2123' && scrollPosition > 66549 }"
            >
              <div class="burger__menu-dropdown-text"></div>
              <div class="burger__menu-dropdown-text">Вопросы нейросети будущего</div>
            </div>
          </div>
        </div>
      </div>
      <div class="burger__bottom">
        <a href="https://msk.rt.ru/" target="_blank" class="burger__rt"></a>
        <div class="burger__socials">
          <div class="burger__socials-title">Поделиться:</div>
          <div class="burger__socials-content">
            <a
              href="javascript:void(0)"
              class="burger__socials-link burger__socials-link--vk"
              data-url="https://xn--b1aajah6aiuf6i5a.xn--e1aapchhekkk.xn--p1ai/"
              data-title="Связь времён: от первобытных сигнальных костров до цифрового завтра!"
              @click="share('vk')"
              data-image="https://xn--b1aajah6aiuf6i5a.xn--e1aapchhekkk.xn--p1ai/1200.jpg"
              data-type="vk"
              ref="vk"
            ></a>
            <a
              href="javascript:void(0)"
              class="burger__socials-link burger__socials-link--telegram"
              data-url="https://xn--b1aajah6aiuf6i5a.xn--e1aapchhekkk.xn--p1ai/"
              data-title="Связь времён: от первобытных сигнальных костров до цифрового завтра!"
              @click="share('telegram')"
              data-type="telegram"
              data-image="https://xn--b1aajah6aiuf6i5a.xn--e1aapchhekkk.xn--p1ai/1200.jpg"
              ref="telegram"
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JSShare from "js-share";
export default {
  name: "Burger",
  data() {
    return {
      opened: 0,
      isMobile: false,
    };
  },
  props: {
    handlerText: {
      type: String,
      required: true,
    },
  },
  computed: {
    scrollPosition() {
      return window.scrollY;
    },
  },
  mounted() {
    if (window.innerWidth < 1000) {
      this.isMobile = true;
    }
    if (this.handlerText == "VI век до н.э." || parseInt(this.handlerText) < 1994) {
      this.opened = 1;
    }
    if (parseInt(this.handlerText) > 1994 && parseInt(this.handlerText) < 2100) {
      this.opened = 2;
    }
    if (parseInt(this.handlerText) > 2100) {
      this.opened = 3;
    }
    if (this.opened == 1 && parseInt(this.handlerText) > 1929) {
      setTimeout(() => {
        if (document.getElementById("section_1")) {
          var objDiv = document.getElementById("section_1");
          objDiv.scrollTop = objDiv.scrollHeight;
        }
        if (document.getElementById("section_mob_1")) {
          var objDivMob = document.getElementById("section_mob_1");
          objDivMob.scrollTop = objDivMob.scrollHeight;
        }
      }, 1);
    }
    if (
      this.opened == 2 &&
      parseInt(this.handlerText) > 2014 &&
      parseInt(this.handlerText) < 2017
    ) {
      setTimeout(() => {
        if (document.getElementById("section_2")) {
          var objDiv = document.getElementById("section_2");
          let h = objDiv.scrollHeight;
          h = h / 4;
          objDiv.scrollTop = h;
        }
        if (document.getElementById("section_2_mob")) {
          var objDiv2 = document.getElementById("section_2_mob");
          let h = objDiv2.scrollHeight;
          h = h / 4;
          objDiv2.scrollTop = h;
        }
      }, 1);
    } else if (this.opened == 2 && parseInt(this.handlerText) > 2016) {
      setTimeout(() => {
        if (document.getElementById("section_2")) {
          var objDiv = document.getElementById("section_2");
          objDiv.scrollTop = objDiv.scrollHeight;
        }
        if (document.getElementById("section_2_mob")) {
          var objDiv2 = document.getElementById("section_2_mob");
          objDiv2.scrollTop = objDiv2.scrollHeight;
        }
      }, 1);
    }
  },
  methods: {
    open(scroll) {
      window.scrollTo({
        top: scroll,
        behavior: "smooth",
      });
      this.$emit("close");
    },
    share(soc) {
      JSShare.go(this.$refs[soc]);
      if (soc == "vk") {
        this.sendEvent("share_vk");
      }
      if (soc == "telegram") {
        this.sendEvent("share_tg");
      }
    },
    openSection(id) {
      if (id == this.opened) {
        this.opened = 0;
      } else {
        this.opened = id;
      }
    },
  },
  watch: {
    handlerText(val) {
      if (val == "VI век до н.э." || parseInt(val) < 1994) {
        this.opened = 1;
      }
    },
  },
};
</script>

<style lang="sass">
@import '@/assets/sass/functions.sass'
.burger
	width: 100vw
	height: 100vh
	height: var(--app-height)
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	margin: auto
	z-index: 99999999999999999
	background-color: rgba(25, 0, 53, 0.8)
	backdrop-filter: blur(6.5px)
	pointer-events: auto
	&__close
		width: vw(50px)
		height: vw(40px)
		right: vw(30px)
		top: vw(30px)
		cursor: pointer
		position: absolute
		z-index: 400
		border: 1px solid #D8B6FF
		background: rgba(216, 182, 255, 0.10)
		border-radius: 2px
		backdrop-filter: blur(2.5px)
		transition: all 300ms
		@media screen and (max-width: 1000px)
			width: vwm(50px)
			height: vwm(40px)
			right: vwm(11px)
			top: vwm(11px)
		&:after
			content: ''
			width: vw(32px)
			height: vw(32px)
			background-size: contain
			background-repeat: no-repeat
			position: absolute
			top: 0
			bottom: 0
			left: 0
			right: 0
			margin: auto
			background-image: url(../assets/img/menu-close-bg.svg)
			@media screen and (max-width: 1000px)
				width: vwm(32px)
				height: vwm(32px)
		&:hover
			background: radial-gradient(40% 50% at 50% 50%, rgba(216, 182, 255, 0.03) 0%, rgba(216, 182, 255, 0.30) 100%)
		&:active
			background: rgba(216, 182, 255, 0.40)
	&__rt
		width: vw(141px)
		height: vw(36px)
		background-image: url(../assets/img/logo-3.svg)
		background-size: contain
		background-repeat: no-repeat
		@media screen and (max-width: 1000px)
			width: vwm(141px)
			height: vwm(36px)
	&__box
		width: vw(460px)
		height: 100vh
		height: var(--app-height)
		background-color: #70F
		right: 0
		position: absolute
		display: flex
		flex-direction: column
		overflow-y: auto
		@media screen and (max-width: 1000px)
			width: 100%
	&__logo
		width: vw(209px)
		margin: auto
		margin-bottom: 0
		margin-top: vw(48px)
		@media screen and (max-width: 1000px)
			width: vwm(209px)
			margin-top: vwm(70px)
		img
			width: 100%
	&__menu
		margin-top: vw(22px)
		@media screen and (max-width: 1000px)
			margin-top: vwm(20px)
		&-arrow
			width: vw(14px)
			height: vw(7px)
			background: url(../assets/img/arrow.svg)
			background-size: contain
			background-repeat: no-repeat
			transition: all 300ms
			@media screen and (max-width: 1000px)
				order: 2
				width: vwm(14px)
				height: vwm(7px)
			&.active
				transform: rotate(180deg)
		&-head
			color: #fff
			font-size: vw(18px)
			font-weight: 500
			display: flex
			align-items: center
			justify-content: space-between
			padding: vw(10.4px) vw(30px)
			transition: all 300ms
			@media screen and (max-width: 1000px)
				font-size: vwm(18px)
				font-weight: 500
				padding: vwm(11px) vwm(30px) vwm(10px) vwm(30px)
			&:hover
				opacity: 0.6
				@media screen and (max-width: 1000px)
					opacity: 1
			&:active
				opacity: 1
			&.active
				opacity: 1
		&-item
			border-top: 1px solid #6600DB
			cursor: pointer
			&:last-child
				border-bottom: 1px solid #6600DB
		&-label
			@media screen and (max-width: 1000px)
				order: 1
		&-dropdown
			background-color: #fff
			max-height: 40vh
			overflow-y: scroll
			&-item
				display: flex
				align-items: center
				justify-content: space-between
				border-bottom: 1px solid #E3E3E3
				padding: vw(9px) vw(31px) vw(7px) vw(30px)
				transition: all 300ms
				@media screen and (max-width: 1000px)
					padding: vwm(11px) vwm(25px) vwm(8px) vwm(30px)
					justify-content: flex-start
				&:hover
					background: linear-gradient(0deg, var(--dark-10, rgba(119, 0, 255, 0.15)) 0%, var(--dark-10, rgba(119, 0, 255, 0.15)) 100%)
				&.active
					background: #6600DB
					.burger__menu-dropdown-text
						color: #fff
				&:active
					background: #6600DB
					.burger__menu-dropdown-text
						color: #fff
			&-text
				font-size: vw(13px)
				line-height: vw(14px)
				font-weight: 500
				color: #433750
				text-align: right
				@media screen and (max-width: 1000px)
					font-size: vwm(13px)
					line-height: vwm(14px)
					text-align: left
				&:first-child
					min-width: 20%
					@media screen and (max-width: 1000px)
						min-width: 31.5%
	&__bottom
		display: flex
		align-items: flex-end
		margin-top: auto
		justify-content: space-between
		padding: vw(20px) vw(31px)
		@media screen and (max-width: 1000px)
			padding: vwm(20px)
	&__socials
		display: flex
		align-items: center
		margin-bottom: -2px
		@media screen and (max-width: 1000px)
			margin-bottom: vwm(-2px)
		&-title
			font-size: vw(13px)
			font-weight: 500
			color: #fff
			margin-right: vw(3px)
			@media screen and (max-width: 1000px)
				font-size: vwm(13px)
				margin-right: vwm(4px)
		&-content
			display: flex
			align-items: center
		&-link
			display: block
			background-size: contain
			background-repeat: no-repeat
			margin-left: vw(14px)
			@media screen and (max-width: 1000px)
				margin-left: vwm(14px)
			&--vk
				background-image: url(../assets/img/vk.svg)
				width: vw(17px)
				height: vw(9px)
				@media screen and (max-width: 1000px)
					width: vwm(17px)
					height: vwm(9px)
			&--telegram
				background-image: url(../assets/img/telegram.svg)
				width: vw(14px)
				height: vw(12px)
				@media screen and (max-width: 1000px)
					width: vwm(14px)
					height: vwm(12px)
			&--ok
				background-image: url(../assets/img/ok.svg)
				width: vw(8px)
				height: vw(14px)
				@media screen and (max-width: 1000px)
					width: vwm(8px)
					height: vwm(14px)
</style>
